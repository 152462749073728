<template>
  <div style="position: relative">
    <a-button class="printBtn" type="primary" v-print="printOptions" @click="printOrder">打印</a-button>
    <a-card title="企业信息" id="printDiv" ref="print">
      <div class="describe">
        <a-form
                ref="formStateRef"
                :model="formState"
                name="basic"
                autocomplete="off"
        >
          <div style="display: flex; justify-content: space-between">
            <a-form-item name="number" label="企业编号">
              <a-input disabled style="width: 500px" v-model="formState.number" />
            </a-form-item>
            <a-form-item name="mch_sign" label="企业标识号">
              <a-input disabled style="width: 500px" v-model="formState.mch_sign" />
            </a-form-item>
          </div>
          <div style="display: flex; justify-content: space-between">
            <a-form-item name="business_licence_num" label="营业执照号码">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.business_licence_num" />
            </a-form-item>
            <a-form-item name="qualifications" label="企业资质">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.credential.qualifications" />
            </a-form-item>
          </div>
          <div style="display: flex; justify-content: space-between">
            <a-form-item name="title" label="企业名称">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.title" />
            </a-form-item>
            <a-form-item name="unit" label="发证单位">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.credential.unit" />
            </a-form-item>
          </div>
          <div style="display: flex; justify-content: space-between">
            <a-form-item name="time" label="发证时间">
              <a-date-picker :disabled="isEdit" style="width: 500px" v-model="formState.credential.time" />
            </a-form-item>
            <a-form-item name="period" label="有效期">
              <a-row>
                <a-col :span="9"><a-date-picker :disabled="isEdit"  v-model="formState.credential.period[0]" /></a-col>
                <a-col :span="9"><a-date-picker :disabled="isEdit"  v-model="formState.credential.period[1]" /></a-col>
                <a-col :span="6" style="text-align: right"><a-checkbox v-model="longValid" @change="changeValid">长期有效</a-checkbox></a-col>
              </a-row>


            </a-form-item>
          </div>
          <a-form-item name="scope" label="许可范围">
            <a-textarea :disabled="isEdit" v-model="formState.credential.scope" />
          </a-form-item>
          <div style="display: flex; justify-content: space-between">
            <a-form-item name="mobile" label="手机号码">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.mobile" />
            </a-form-item>
            <a-form-item name="company_name" label="店铺公司名称">
              <a-input :disabled="isEdit" style="width: 500px" v-model="formState.company_name" />
            </a-form-item>
          </div>
          <div style="display: flex">
            <a-form-item name="logo" label="logo" style="margin-right: 4%; width: 48%">
              <a-upload
                      accept=".png,.jpg,.jpeg"
                      :action="uploadUrl"
                      :disabled="isEdit"
                      :maxCount="1"
                      list-type="picture-card"
                      :file-list="formState.logo"
                      @preview="handlePreview"
                      @change="handleChange1"
                      name="file">
                <div v-if="(formState.logo && formState.logo.length < 1) || formState.logo == null">
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
            </a-form-item>
            <a-form-item name="resource" label="是否向公众公开">
              <a-radio-group :disabled="isEdit" v-model="formState.credential.resource" :options="plainOptions" />
            </a-form-item>
          </div>
          <a-form-item name="address_name" label="地址">
            <a-input :disabled="isEdit" v-model="formState.address_name" />
          </a-form-item>
          <a-form-item name="address_details" label="详细地址">
            <a-input :disabled="isEdit" v-model="formState.address_details" />
          </a-form-item>
          <a-form-item name="certificate" label="资质证书">
            <a-upload
                    accept=".png,.jpg,.jpeg,.pdf"
                    :action="uploadUrl"
                    :disabled="isEdit"
                    :maxCount="3"
                    list-type="picture-card"
                    :file-list="formState.credential.certificate"
                    @preview="handlePreview"
                    @change="handleChange"
                    name="file">
                <div v-if="(formState.credential && formState.credential.certificate && formState.credential.certificate.length < 3) || formState.credential == null || formState.credential.certificate == null">
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
            </a-upload>
            <!-- <a-upload
                    :disabled="isEdit"
                    :max-count="3"
                    v-model="fileList"
                    :action="uploadUrl"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                上传文件
              </a-button>
            </a-upload> -->
            <p style="color: red; margin-top: 10px">最多只能上传3张图片</p>
          </a-form-item>
          <a-form-item style="text-align: center" :wrapper-col="{ span: 14, offset: 4 }">
            <a-button v-if="isEdit" type="primary" @click="onEdit">
              编辑
            </a-button>
            <a-button v-if="!isEdit" type="primary" @click="onSubmit">
              提交
            </a-button>
            <a-button v-if="!isEdit" style="margin-left: 10px;" @click="onCancel">
              取消
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
    <a-modal v-model="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
  import { getInfo, editInfo } from "@/api/enterprise";
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  export default {
    data() {
      return {
        previewImage: '',
        longValid:false,
        previewVisible: false,
        uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
        printOptions: {
          id: "printDiv",
          popTitle: "正常打印",
          extraHead: "",
        },
        isEdit: true,
        fileList: [],
        formState: {
          credential: {
            period:['','']
          }
        },
        plainOptions: ['是', '否'],
        required: true,

      }
    },
    created() {
      this.getData()
    },
    methods: {
      changeValid(){
        if(this.longValid){
          this.formState.credential.period[1]=''
        }

      },
      handleCancel() {
        this.previewVisible = false;
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      handleChange({ fileList }) {
        this.$set(this.formState.credential,'certificate',fileList);
      },
      handleChange1({ fileList }) {
        this.$set(this.formState,'logo',fileList);
      },
      printOrder() {
        this.$print(this.$refs.print)
      },
      onEdit() {
        this.isEdit = false
      },
      async onSubmit() {
        this.formState.delivery_credit = this.formState.delivery_credit * 1
        this.formState.desc_credit = this.formState.desc_credit * 1
        this.formState.service_credit = this.formState.service_credit * 1
        this.formState.credential.certificate = this.formState.credential && this.formState.credential.certificate && this.formState.credential.certificate
                .filter((item) => item.status === 'done')
                .map((item) => {
                  if (item.url) return item.url;
                  return (item.response).data.file_url;
                })
        this.formState.logo = this.formState.logo && this.formState.logo
                .filter((item) => item.status === 'done')
                .map((item) => {
                  if (item.url) return item.url;
                  return (item.response).data.file_url;
                })
        console.log(this.formState);
        await editInfo(this.formState)
        this.$message.success("提交成功");
        this.isEdit = true
        this.getData();
      },
      onCancel() {
        this.isEdit = true
      },
      async getData() {
        const res1 = await getInfo();
        const res = res1.data
        if(res.credential) {
          this.formState = res
        } else {
          this.formState = res
          this.formState.credential = {}
        }
        this.formState.logo = res.logo && res.logo.map((item, index) => {
          const n = item.split('/')
          return {
            uid: index + '',
            name: n[n.length-1],
            status: 'done',
            url: item,
          }
        });
        this.formState.credential.certificate = res.credential && res.credential.certificate && res.credential.certificate.map((item, index) => {
          const n = item.split('/')
          return {
            uid: index + '',
            name: n[n.length-1],
            status: 'done',
            url: item,
          }
        });
      }
    }
  };
</script>

<style>
  .printBtn{
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 999;
  }
  .describe{
    padding: 0 80px;
  }
</style>
